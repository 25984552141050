import { Component } from "react"
import { Container ,Grid} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
import { navigate } from "../../../helpers/queryNavigate"

// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
import SimpleAccordion from '../../elements/ControlledAccordions'
class DudasCmp extends Component {    

    render() {
        const {            
            titleIcon=<span>
                            <span style={{color:"rgb(213 28 28)",fontSize:"16px"}}>Preguntas frecuentes</span><br/>
                            <span style={{color:"rgb(53 72 16)",fontSize:"30px"}}><strong>¿Tienes dudas?</strong></span><br/>
                            <span style={{color:"rgb(213 28 28)",fontSize:"16px"}}>Selecciona una pregunta para ver su respuesta</span><br/>              
                      </span>,
            txtTitlePago=<span style={{color:"rgb(53 72 16)"}}><strong>¿Qué tengo que hacer para tramitar un préstamo?</strong></span>,
            txtCntPago=<span>                            
                                En California, puedes acudir a cualquiera de nuestras 27 sucursales con una identificación oficial vigente (California ID, matrícula consular o Pasaporte), comprobante de ingresos (cheque de tu empresa) y comprobante de domicilio. En Arizona y Texas puedes iniciar tu solicitud por teléfono en el 800-891-2778, o si prefieres <a href="javasript:void(0);" onClick={()=>navigate("/")} style={{color:"rgb(53 72 16)"}}>puedes iniciar la solicitud de tu préstamo aquí.</a>
                            <br/>&nbsp;
                        </span>,
            txtTitleApp=<span style={{color:"rgb(53 72 16)"}}><strong>¿Qué puedo hacer si no tengo comprobante de domicilio?</strong></span>,
            txtCntApp=<span>
                            Puedes pedirle a uno de los ejecutivos de la sucursal que te asesore.
                        <br/>&nbsp;
                    </span>,
            txtTitleRecomienda=<span style={{color:"rgb(53 72 16)"}}><strong>¿Puedo pedir un préstamo si trabajo por mi cuenta y no tengo cheques de empresa?</strong></span>,
            txtCntRecomienda=<span>
                                Por supuesto, te recomendamos hablar con uno de nuestros ejecutivos de la sucursal para que te asesore.
                                <br/>&nbsp;
                            </span>,
            txtTitlePromotions=<span style={{color:"rgb(53 72 16)"}}><strong>¿Puede firmar alguien más conmigo en el préstamo?</strong></span>,
            txtCntPromotions=<span>
                                Sí, puede ser algún familiar. Además pueden sumar sus ingresos para facilitar la obtención de su préstamo.
                                <br/>&nbsp;
                            </span>,

            txtTitleEduf=<span style={{color:"rgb(53 72 16)"}}><strong>¿Para qué puedo pedir un préstamo?</strong></span>,
            txtCntEduf=<span>
                            Para lo que necesites: enviar dinero a tu familia, ampliar tu negocio, ampliar o remodelar tu casa aquí o en tu país de origen, comprar muebles, cambiar tu auto, emergencias médicas, compra de útiles escolares para tus hijos, etc.; ¡nosotros siempre estamos para apoyarte!.
                            <br/>&nbsp;
                        </span>,

            txtTitleComision=<span style={{color:"rgb(53 72 16)"}}><strong>¿Cobran comisiones?</strong></span>,
            txtCntComision=<span>
                Si cobramos una comisión administrativa, pero esta comisión no se descuenta a la cantidad que solicitaste, pues ya está considerada en los pagos que vas a realizar.
                <br/>&nbsp;
            </span>,
            
            txtTitlePrestamo=<span style={{color:"rgb(53 72 16)"}}><strong>¿En cuánto tiempo me autorizan mi préstamo?</strong></span>,
            txtCntPrestamo=<span>
               Te damos respuesta en máximo 2 días hábiles. En la mayoría de los casos el préstamo se te entrega a los dos días de que presentaste toda la documentación.
                <br/>&nbsp;
            </span>,

            txtTitleEntregan=<span style={{color:"rgb(53 72 16)"}}><strong>¿Cómo entregan el dinero de mi préstamo?</strong></span>,
            txtCntEntregan=<span>
                Con un cheque a tu nombre.
                <br/>&nbsp;
            </span>,

            txtTitleCuando=<span style={{color:"rgb(53 72 16)"}}><strong>¿Cada cuándo tengo que hacer los pagos de mi préstamo?</strong></span>,
            txtCntCuando=<span>
                Puedes pagar de manera catorcenal, quincenal o mensual, dependiendo de cómo recibas tu ingreso.
                <br/>&nbsp;
            </span>,
            
            txtTitleDonde=<span style={{color:"rgb(53 72 16)"}}><strong>¿Dónde y en qué horario puedo hacer mis pagos?</strong></span>,
            txtCntDonde=<span>
                Apoyo Financiero te ofrece las siguientes opciones de pago a fin de que elijas la que más te convenga.<br/>
                Descarga nuestra App para realizar tus pagos con tarjeta de débito/cuenta de banco.<br/>
                PayNearMe (en tiendas 7-Eleven, Farmacias CVS, Walmart y Family Dollar participantes)<br/>
                Para tu comodidad, ahora puedes pagar tu préstamo con efectivo a cualquier hora del día.<br/>
                <ol>
                    <li>Presenta tu código de barras personalizado</li>
                    <li>Muestra al cajero tu código.</li>
                    <li>Conserva tu recibo de pago como comprobante</li>
                </ol>
                Si requieres copia del código de barras de PayNearMe llama a tu sucursal para que te envíen el código por correo electrónico o vía mensaje de texto a tu celular.<br/><br/>
                Para encontrar un establecimiento PayNearMe cerca de ti, visita: <a style={{color:"rgb(53 72 16)"}} href="paynearme.com/es/payment-locations" target="_blank" rel="nofollow noopener noreferrer">paynearme.com/es/payment-locations</a>
                <br/>&nbsp;
            </span>,

            txtTitleAbonos=<span style={{color:"rgb(53 72 16)"}}><strong>¿Puedo hacer abonos a capital o liquidar antes?</strong></span>,
            txtCntAbonos=<span>
                Claro, puedes abonar a capital el día de tu pago, y liquidar tu préstamo antes del tiempo establecido ¡sin penalización! Sólo te pedimos que estés al corriente en tus pagos.
                <br/>&nbsp;
            </span>,

            txtTitleCambie=<span style={{color:"rgb(53 72 16)"}}><strong>¿Qué puedo hacer si ya tengo mi préstamo pero cambié de domicilio?</strong></span>,
            txtCntCambie=<span>
                Debes ir a la sucursal más cercana y pedirle a un ejecutivo que actualice tus datos.
                <br/>&nbsp;
            </span>,

            txtTitleIntereses=<span style={{color:"rgb(53 72 16)"}}><strong>¿Hay alguna forma en que pueda ahorrarme intereses?</strong></span>,
            txtCntIntereses=<span>
                Sí, de hecho tienes varias opciones:<br/><br/>
                - Haciendo abonos a capital.<br/><br/>
                - Liquidando tu préstamo antes del tiempo establecido.<br/><br/>
                <br/>&nbsp;
            </span>,

            txtTitleAtraso=<span style={{color:"rgb(53 72 16)"}}><strong>¿Qué pasa si me atraso en mis pagos?</strong></span>,
            txtCntAtraso=<span>
                Como en todos los créditos o préstamos que contratas, los atrasos generan intereses moratorios ($14 dólares). Te recomendamos estar al pendiente de tus días de pago, abonar a<br/><br/> capital y adelantar pagos siempre que te sea posible. Recuerda que un historial de crédito sano, es la puerta abierta a otro préstamo con nosotros y las demás instituciones de crédito.
                <br/>&nbsp;
            </span>,

            txtTitleVolver=<span style={{color:"rgb(53 72 16)"}}><strong>¿Me pueden volver a prestar?</strong></span>,
            txtCntVolver=<span>
                Claro, las veces que quieras, sólo necesitas estar al corriente en tus pagos. Con nosotros obtienes no sólo un préstamo personal, sino la posibilidad de tener crédito para toda la vida.
                <br/>&nbsp;
            </span>,

            txtTitleQueja=<span style={{color:"rgb(53 72 16)"}}><strong>¿Donde me puedo comunicar para recibir asistencia general o presentar una queja?</strong></span>,
            txtCntQueja=<span>
                Puede comunicarse al <a href="tel:18008912778" rel="nofollow noopener noreferrer" style={{color:"rgb(53 72 16)"}}>1-800-891-2778</a>, enviar un correo electrónico a la cuenta <a href="mailto:contactus@apoyofin.com" rel="nofollow noopener noreferrer" style={{color:"rgb(53 72 16)"}}>contactus@apoyofin.com</a> o bien <a href="/es/contacto" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(53 72 16)"}}>llenar el formulario adjunto.</a>
                <br/>&nbsp;
            </span>
            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>                                                
                        {titleIcon}                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>                    
                    <br/><br/>

                    <SimpleAccordion header expanded={false} titulo={txtTitlePago}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntPago}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleApp}>
                        <Grid Container>                            
                            <Grid item xs={12}>
                                {txtCntApp}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleRecomienda}>
                        <Grid Container>                           
                            <Grid item xs={12}>
                                {txtCntRecomienda}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>
 
                    <SimpleAccordion header expanded={false} titulo={txtTitlePromotions}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntPromotions}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                     <SimpleAccordion header expanded={false} titulo={txtTitleEduf}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntEduf}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleComision}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntComision}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitlePrestamo}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntPrestamo}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleEntregan}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntEntregan}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleCuando}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntCuando}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>

                    <SimpleAccordion header expanded={false} titulo={txtTitleDonde}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntDonde}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>  

                    <SimpleAccordion header expanded={false} titulo={txtTitleAbonos}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntAbonos}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion> 

                    <SimpleAccordion header expanded={false} titulo={txtTitleCambie}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntCambie}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion> 

                    <SimpleAccordion header expanded={false} titulo={txtTitleIntereses}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntIntereses}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion> 

                    <SimpleAccordion header expanded={false} titulo={txtTitleAtraso}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntAtraso}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>        

                    <SimpleAccordion header expanded={false} titulo={txtTitleVolver}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntVolver}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion> 

                    <SimpleAccordion header expanded={false} titulo={txtTitleQueja}>
                        <Grid Container>
                            <Grid item xs={12}>
                                {txtCntQueja}                                     
                            </Grid>                        
                        </Grid>
                    </SimpleAccordion>       
                        
                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

DudasCmp.propTypes = {      
    titlePrimary: PropTypes.string,
    titleIcon: PropTypes.string,
    txtTitlePago: PropTypes.string,
    txtCntPago: PropTypes.string,
    txtTitleApp: PropTypes.string,
    txtCntApp: PropTypes.string,
    txtTitleRecomienda: PropTypes.string,
    txtCntRecomienda: PropTypes.string,
    txtTitlePromotions: PropTypes.string,
    txtCntPromotions: PropTypes.string,
    txtTitleEduf: PropTypes.string,
    txtCntEduf: PropTypes.string,
    txtTitleComision: PropTypes.string,
    txtCntComision: PropTypes.string,            
    txtTitlePrestamo: PropTypes.string,
    txtCntPrestamo: PropTypes.string,
    txtTitleEntregan: PropTypes.string,
    txtCntEntregan: PropTypes.string,
    txtTitleCuando: PropTypes.string,
    txtCntCuando: PropTypes.string,            
    txtTitleDonde: PropTypes.string,
    txtCntDonde: PropTypes.string,
    txtTitleAbonos: PropTypes.string,
    txtCntAbonos: PropTypes.string,
    txtTitleCambie: PropTypes.string,
    txtCntCambie: PropTypes.string,
    txtTitleIntereses: PropTypes.string,
    txtCntIntereses: PropTypes.string,
    txtTitleAtraso: PropTypes.string,
    txtCntAtraso: PropTypes.string,
    txtTitleVolver: PropTypes.string,
    txtCntVolver: PropTypes.string,
    txtTitleQueja: PropTypes.string,
    txtCntQueja: PropTypes.string
}

export default withThemeProps(DudasCmp, 'VDPNDudas')